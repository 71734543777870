import { Box, CircularProgress, TextField } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import ErrorComponent from "components/common/ErrorComponent/ErrorComponent";
import AnimatedButton from "components/custom/AnimatedButton";
import {
  fetchUserProfileDetails,
  updateUserProfileDetails,
} from "modules/Profile/api";
import { IUpdateUserDetails, IUserDetails } from "modules/Profile/types";
import { useContext } from "react";
import { useT } from "translation";

const UpdateUserPersonalDetails = () => {
  const { t } = useT();
  const { setToast } = useContext(ToastCtx);
  const queryClient = useQueryClient();
  const {
    data: userDetails,
    isLoading: isUserDetailsLoading,
    isError: isUserDetailsError,
  } = useQuery<IUserDetails>({
    queryFn: fetchUserProfileDetails.queryFn,
    queryKey: fetchUserProfileDetails.queryKey,
  });
  const updateUserDetailsMutation = useMutation<
    unknown,
    any,
    IUpdateUserDetails
  >({
    mutationFn: updateUserProfileDetails,
    onSuccess: () => {
      setToast({
        severity: "success",
        text: t("PROFILE_UPDATE"),
      });
      queryClient.invalidateQueries({
        queryKey: fetchUserProfileDetails.queryKey,
      });
    },
    onError: (err) => {
      const errorCode = err?.error?.code || "";
      if (errorCode === "invalid_auth_key") {
        setToast({
          severity: "error",
          text: t("INVALID_AUTH_KEY"),
        });
        return;
      }
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  const userDetailsSchema = Yup.object().shape({
    firstName: Yup.string().required(t("FIRST_NAME_REQUIRED")),
    lastName: Yup.string().required(t("LAST_NAME_REQUIRED")),
    email: Yup.string().required(t("EMAIL_REQUIRED")),
  });

  if (isUserDetailsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (isUserDetailsError) {
    return <ErrorComponent />;
  }

  const handleUpdate = (values: typeof initialValues) => {
    const updatedUserDetails: IUpdateUserDetails = {
      first_name: values.firstName,
      last_name: values.lastName,
    };
    updateUserDetailsMutation.mutate(updatedUserDetails);
  };

  const initialValues = {
    firstName: userDetails?.first_name || "",
    lastName: userDetails?.last_name || "",
    email:
      userDetails?.attributes?.find((attr) => attr.type === "email")?.value ||
      "",
  };

  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={userDetailsSchema}
        validateOnMount={true}
        onSubmit={handleUpdate}
      >
        {({ isValid }) => (
          <Form>
            <Box
              display={"flex"}
              flexDirection="column"
              alignItems={"flex-start"}
              gap={"4px"}
            >
              <>
                <Field
                  as={TextField}
                  label={t("FIRST_NAME")}
                  variant="outlined"
                  name="firstName"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      width: "100%",
                    },
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  name="firstName"
                  component="div"
                />
              </>
              <>
                <Field
                  as={TextField}
                  label={t("LAST_NAME")}
                  variant="outlined"
                  name="lastName"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      width: "100%",
                    },
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  name="lastName"
                  component="div"
                />
              </>
              <>
                <Field
                  as={TextField}
                  label={t("PERSONAL_FORM_EMAIL")}
                  variant="outlined"
                  name="email"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      width: "100%",
                    },
                  }}
                  disabled
                />
                <ErrorMessage
                  className="error-message"
                  name="email"
                  component="div"
                />
              </>

              <AnimatedButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isValid}
                isLoading={updateUserDetailsMutation.isPending}
              >
                {t("UPDATE")}
              </AnimatedButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default UpdateUserPersonalDetails;
