import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import AnimatedButton from "components/custom/AnimatedButton";
import { IChildAccount } from "modules/Accounts/types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedAccountV2Login } from "store/features/authSlice";
import { authSelector, customStylesSelector } from "store/selectors/selectors";

function AccountSwitcher() {
  const dispatch = useDispatch();
  const {
    v2Login: { selectedAccount, activeAccounts },
  } = useSelector(authSelector);
  const customStyles = useSelector(customStylesSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showAccountsList, setShowAccountList] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowAccountList(!showAccountsList);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShowAccountList(!showAccountsList);
    setAnchorEl(null);
  };

  const handleAccountClick = (account: IChildAccount) => {
    navigate("/home/dashboard");
    dispatch(setSelectedAccountV2Login(account));
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "account-switcher" : undefined;
  if (!selectedAccount) return null;
  return (
    <Box>
      <AnimatedButton
        aria-describedby={id}
        onClick={handleClick}
        variant="outlined"
        sx={{
          position: "relative",
          color: customStyles?.muiTheme?.palette?.primary?.main,
          py: "8px",
          ml: "20px",
          backgroundColor: "white",
          borderRadius: "12px",
          border: `1px solid ${customStyles?.muiTheme?.palette?.primary?.main}`,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontWeight: 500 }}>
            {selectedAccount.name}
          </Typography>
          {showAccountsList ? (
            <KeyboardArrowUpIcon
              style={{
                width: "0.8em",
                marginLeft: "5px",
                color: customStyles?.muiTheme?.palette?.primary?.main,
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              style={{
                width: "0.8em",
                marginLeft: "5px",
                color: customStyles?.muiTheme?.palette?.primary?.main,
              }}
            />
          )}
        </Box>
      </AnimatedButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          mt: "10px",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={{ p: "0px", minWidth: "150px" }}>
          {activeAccounts.map((account) => (
            <ListItemButton
              key={account.id}
              id={account.id}
              selected={selectedAccount.id === account.id}
              sx={{
                backgroundColor:
                  selectedAccount.id === account.id
                    ? `${customStyles?.button?.backgroundColor} !important`
                    : "white",
              }}
              onClick={() => {
                if (selectedAccount.id !== account.id)
                  handleAccountClick(account);
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color:
                        selectedAccount.id === account.id
                          ? "white"
                          : customStyles?.button?.backgroundColor,
                      textTransform: "none",
                    }}
                  >
                    {account.name}
                  </Typography>
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </Box>
  );
}

export default AccountSwitcher;
