import { CircularProgress, debounce } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useMutation } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import { AgentInviteCtx } from "modules/Agents/AgentInviteDialog";
import { AgentFilterCtx } from "modules/Agents/AgentsFilterProvider";
import NoRecordsFound from "modules/Agents/NoRecordsFound";
import { fetchMembersApi } from "modules/Agents/api";
import { IFetchMemberPayload, IMember } from "modules/Agents/types";
import useDeleteMember from "modules/Agents/useDeleteMember";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useT } from "translation";
import { IListResponse } from "types";

interface Data {
  id: string;
  email: string;
  action: React.ReactNode;
}

interface HeadCell {
  id: keyof Data;
  label: string;
  rightAlign: boolean;
  isSortable: boolean;
}

function EnhancedTableHead() {
  const { t } = useT();
  const headCells: readonly HeadCell[] = [
    {
      id: "email",
      rightAlign: false,
      label: t("EMAIL_LABEL"),
      isSortable: true,
    },
    {
      id: "action",
      rightAlign: false,
      label: t("TABLES.action"),
      isSortable: false,
    },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.rightAlign ? "right" : "left"}
            padding={"normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function InviteAgentsTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { setToast } = React.useContext(ToastCtx);
  const { t } = useT();
  const accountId = useSelector(
    (state: RootState) => state.auth.v2Login?.selectedAccount?.id
  );
  const { inviteSuccess } = React.useContext(AgentInviteCtx);
  const { deleteMember, isDeleting, isDeleteSuccess } = useDeleteMember();
  const agentFilters = React.useContext(AgentFilterCtx);
  const {
    mutate: fetchMemberList,
    data,
    isPending,
  } = useMutation<IListResponse<IMember>, any, IFetchMemberPayload>({
    mutationFn: (data: IFetchMemberPayload) => fetchMembersApi(data, accountId),
    onError: ({ error }) => {
      if (error.code === "insufficient_permissions") {
        return setToast({
          severity: "error",
          text: t("NO_SUFFICIENT_PERMISSION"),
        });
      }
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchMemberList = React.useCallback(
    debounce(fetchMemberList, 300),
    [fetchMemberList]
  );

  React.useEffect(() => {
    const requestPayload = {
      limit_by: {
        page: page + 1,
        per_page: rowsPerPage,
      },
      metrics: ["applications", "policies"],
      expand: ["user", "agent", "account"],
      statuses: ["invited"],
      ...(!Boolean(agentFilters.email) ? {} : { email: agentFilters.email }),
      ...(!Boolean(agentFilters.first_name)
        ? {}
        : { name: agentFilters.first_name }),
    };
    debouncedFetchMemberList(requestPayload);
  }, [
    agentFilters.email,
    agentFilters.first_name,
    agentFilters?.status,
    debouncedFetchMemberList,
    page,
    rowsPerPage,
    isDeleteSuccess,
    inviteSuccess,
  ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = React.useMemo(() => data?.data || [], [data?.data]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead />
            <TableBody>
              {rows.map((row, index) => {
                const labelId = `enhanced-table-${index}`;

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" id={labelId} scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row">
                      <AnimatedButton
                        variant="contained"
                        isLoading={isDeleting}
                        onClick={() =>
                          deleteMember(row.id as string, row.account.id)
                        }
                      >
                        {t("CANCEL")}
                      </AnimatedButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length === 0 && !isPending && <NoRecordsFound />}
        {isPending && (
          <Box display={"flex"} justifyContent={"center"} p={4}>
            <CircularProgress />
          </Box>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
