import { Box, CircularProgress, TextField } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import ErrorComponent from "components/common/ErrorComponent/ErrorComponent";
import AnimatedButton from "components/custom/AnimatedButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IAgencyDetails } from "modules/Accounts/types";
import useIsAdmin from "modules/Agents/useIsAdmin";
import { fetchAccountDetails, updateAccountAPI } from "modules/Profile/api";
import { IAccountDetails } from "modules/Profile/types";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useT } from "translation";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";
import * as Yup from "yup";
const getAllAgencyDetails = (data: IAccountDetails) => {
  const details = {
    name: data?.intermediary.name || "",
    license: "",
    taxId: "",
    address: data?.intermediary.address || "",
  };
  data?.intermediary?.regulatory_identifiers?.forEach((identifier) => {
    if (identifier.type === "tax_id") {
      details.taxId = identifier?.identifier || "";
    } else if (identifier.type === "license_id") {
      details.license = identifier?.identifier || "";
    }
  });
  return details;
};
function UpdateAgencyDetails() {
  const isAdmin = useIsAdmin();
  const { t } = useT();
  const agencyDetailsValidationSchema = Yup.object().shape({
    agencyName: Yup.string().required(t("AGENCY_NAME_IS_REQUIRED")),
    agencyAddress: Yup.string().required(t("AGENCY_ADDRESS_IS_REQUIRED")),
  });

  const selectedAccount = useSelector(
    (state: RootState) => state.auth.v2Login?.selectedAccount
  );
  const accountId = selectedAccount?.id;
  const { setToast } = useContext(ToastCtx);
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery<IAccountDetails>({
    queryKey: ["get-account-details", accountId],
    queryFn: () => fetchAccountDetails(accountId),
    enabled: Boolean(accountId),
  });

  const updateAgencyDetailsMutation = useMutation<unknown, any, IAgencyDetails>(
    {
      mutationFn: (data: IAgencyDetails) => updateAccountAPI(data, accountId),
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: ["get-account-details", accountId],
        });
        queryClient.invalidateQueries({
          queryKey: ["accounts", getFromLocalStorage(LSKeys.ACCESS_TOKEN)],
        });
        setToast({
          severity: "success",
          text: t("ACCOUNT_UPDATED"),
        });
      },
      onError: () => {
        setToast({
          severity: "error",
          text: t("SOMETHING_WENT_WRONG"),
        });
      },
    }
  );

  const { name, license, taxId, address } = getAllAgencyDetails(data);

  const initialAgencyDetails = {
    cnpj: taxId,
    licenseNumber: license,
    agencyName: name,
    agencyAddress: address,
  };

  const handleUpdate = (values) => {
    updateAgencyDetailsMutation.mutate({
      name: values.agencyName,
      regulatory_info: [
        { tax_id: values.cnpj },
        ...(values.licenseNumber ? [{ license: values.licenseNumber }] : []),
      ],
      address: values.agencyAddress,
    });
  };

  if (!selectedAccount) return null;

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <ErrorComponent />;
  }

  return (
    <Formik
      initialValues={initialAgencyDetails}
      validationSchema={agencyDetailsValidationSchema}
      validateOnMount={true}
      onSubmit={handleUpdate}
    >
      {({ isValid }) => (
        <Form>
          <Box
            display={"flex"}
            flexDirection="column"
            alignItems={"flex-start"}
            gap={"4px"}
          >
            <>
              <Field
                as={TextField}
                label={t("AGENCY_NAME")}
                variant="outlined"
                name="agencyName"
                margin="normal"
                fullWidth
                disabled={!isAdmin}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    width: "100%",
                  },
                }}
              />
              <ErrorMessage
                className="error-message"
                name="agencyName"
                component="div"
              />
            </>
            <>
              <Field
                as={TextField}
                label={t("TAX_ID")}
                variant="outlined"
                name="cnpj"
                margin="normal"
                disabled={true}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
              <ErrorMessage
                className="error-message"
                name="cnpj"
                component="div"
              />
            </>
            <>
              <Field
                as={TextField}
                label={t("AGENCY_ADDRESS")}
                variant="outlined"
                name="agencyAddress"
                margin="normal"
                fullWidth
                disabled={!isAdmin}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
              <ErrorMessage
                className="error-message"
                name="agencyAddress"
                component="div"
              />
            </>
            <>
              <Field
                as={TextField}
                fullWidth
                label={t("LICENSE_NUMBER")}
                disabled={true}
                variant="outlined"
                name="licenseNumber"
                margin="normal"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
              <ErrorMessage
                className="error-message"
                name="licenseNumber"
                component="div"
              />
            </>
            <AnimatedButton
              variant="contained"
              color="primary"
              type="submit"
              isLoading={updateAgencyDetailsMutation.isPending}
              disabled={!isAdmin || !isValid}
            >
              {t("UPDATE")}
            </AnimatedButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default UpdateAgencyDetails;
