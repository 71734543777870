import { Box, TextField, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import StereLogo from "assets/logos/StereLogo";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  createAccountAPI,
  generateKeysForChildAccountAPI,
} from "modules/Accounts/api";
import {
  IAgencyDetails,
  ICreateAccountRes,
  IGenerateKeysRes,
} from "modules/Accounts/types";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";
import * as Yup from "yup";

const initialAgencyDetails = {
  cnpj: "",
  licenseNumber: "",
  agencyName: "",
  agencyAddress: "",
};

const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
export const useAgencyValidationSchema = () => {
  const { t } = useT();
  const agencyDetailsValidationSchema = Yup.object().shape({
    cnpj: Yup.string()
      .required(t("CNPJ_IS_REQUIRED"))
      .matches(cnpjRegex, t("INVALID_CNPJ_FORMAT"))
      .test("is-valid-cnpj", t("INVALID_CNPJ"), (value) => {
        if (!value) return false;

        // Clean the CNPJ removing non-numeric characters
        const cnpj = value.replace(/[^\d]+/g, "");

        // Check if all digits are the same
        if (/^(\d)\1+$/.test(cnpj)) return false;

        // Validate length
        if (cnpj.length !== 14) return false;

        // Validate DVs
        const validateDV = (cnpj, factor) => {
          let sum = 0;
          for (let i = 0; i < factor.length; i++) {
            sum += parseInt(cnpj[i]) * factor[i];
          }
          let result = sum % 11;
          return result < 2 ? 0 : 11 - result;
        };

        const dv1 = validateDV(cnpj, [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);
        const dv2 = validateDV(cnpj, [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);

        return cnpj[12] === String(dv1) && cnpj[13] === String(dv2);
      }),
    licenseNumber: Yup.string(),
    agencyName: Yup.string().required(t("AGENCY_NAME_IS_REQUIRED")),
    agencyAddress: Yup.string().required(t("AGENCY_ADDRESS_IS_REQUIRED")),
  });
  return agencyDetailsValidationSchema;
};
const AgencyDetails = () => {
  const customStyles = useSelector(customStylesSelector);
  const { setToast } = useContext(ToastCtx);
  const navigate = useNavigate();
  const { t } = useT();
  const queryClient = useQueryClient();
  const agencyDetailsValidationSchema = useAgencyValidationSchema();

  const createAccountMutation = useMutation<
    ICreateAccountRes,
    any,
    IAgencyDetails
  >({
    mutationFn: createAccountAPI,
    onSuccess: (data) => {
      generateKeysMutation.mutate(data.id);
    },
    onError: () => {
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  const generateKeysMutation = useMutation<IGenerateKeysRes, any, string>({
    mutationFn: generateKeysForChildAccountAPI,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: ["accounts", getFromLocalStorage(LSKeys.ACCESS_TOKEN)],
        })
        .then(() => {
          navigate("/home/dashboard", { replace: true });
        });
    },
    onError: () => {
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  const handleSave = (values: typeof initialAgencyDetails) => {
    createAccountMutation.mutate({
      name: values.agencyName,
      regulatory_info: [
        { tax_id: values.cnpj },
        ...(values.licenseNumber ? [{ license: values.licenseNumber }] : []),
      ],
      address: values.agencyAddress,
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={"column"}
      {...animateRouteProps}
    >
      <Box>
        <StereLogo />
      </Box>
      <Box
        textAlign="center"
        p={3}
        width="60%"
        display="flex"
        flexDirection="column"
      >
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontWeight: 700, marginBottom: "16px" }}
        >
          {t("AGENCY_DETAILS")}
        </Typography>
        <Formik
          initialValues={initialAgencyDetails}
          validationSchema={agencyDetailsValidationSchema}
          validateOnMount={true}
          onSubmit={handleSave}
        >
          {({ isValid }) => (
            <Form>
              <Box
                display={"flex"}
                flexDirection="column"
                alignItems={"flex-start"}
                gap={"4px"}
              >
                <>
                  <Field
                    as={TextField}
                    label={t("AGENCY_NAME")}
                    variant="outlined"
                    name="agencyName"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        width: "100%",
                      },
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="agencyName"
                    component="div"
                  />
                </>
                <>
                  <Field
                    as={TextField}
                    label={t("TAX_ID")}
                    variant="outlined"
                    name="cnpj"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                      },
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="cnpj"
                    component="div"
                  />
                </>
                <>
                  <Field
                    as={TextField}
                    label={t("AGENCY_ADDRESS")}
                    variant="outlined"
                    name="agencyAddress"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                      },
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="agencyAddress"
                    component="div"
                  />
                </>
                <>
                  <Field
                    as={TextField}
                    fullWidth
                    label={t("LICENSE_NUMBER")}
                    variant="outlined"
                    name="licenseNumber"
                    margin="normal"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                      },
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="licenseNumber"
                    component="div"
                  />
                </>
                <AnimatedButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  isLoading={createAccountMutation.isPending}
                >
                  {t("SAVE")}
                </AnimatedButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AgencyDetails;
