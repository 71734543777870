import { useQuery } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import { isEqual } from "lodash";
import { IChildAccount } from "modules/Accounts/types";
import { fetchAccountsOfUserApi } from "modules/Auth/api";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setActiveAccountsV2Login,
  setAllAccountsV2Login,
  setInvitedAccountV2Login,
  setSelectedAccountV2Login,
} from "store/features/authSlice";
import { RootState } from "store/store";
import { useT } from "translation";
import { IListResponse } from "types";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";

function useAccountsInfo() {
  const accessToken = getFromLocalStorage(LSKeys.ACCESS_TOKEN);
  const { setToast } = useContext(ToastCtx);
  const dispatch = useDispatch();
  const { t } = useT();
  const navigate = useNavigate();
  const location = useLocation();
  const isInviteRoute = location.pathname.includes("/accept-invite");
  const [isAccountsLoading, setIsAccountsLoading] = useState(
    isInviteRoute ? false : !!accessToken
  );
  const selectedAccount = useSelector(
    (state: RootState) => state.auth.v2Login.selectedAccount
  );
  const {
    data: accountsRes,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useQuery<IListResponse<IChildAccount>>({
    queryKey: ["accounts", accessToken],
    queryFn: fetchAccountsOfUserApi,
    enabled: Boolean(accessToken) && !isInviteRoute,
    refetchOnWindowFocus: false,
  });

  const accountsList = useMemo(
    () => accountsRes?.data || [],
    [accountsRes?.data]
  );

  useEffect(() => {
    if (isLoading) {
      setIsAccountsLoading(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!accessToken || isLoading || isFetching || isInviteRoute) return;
    if (isError) {
      setIsAccountsLoading(false);
      return setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    }
    if (isSuccess) {
      setIsAccountsLoading(false);
      if (accountsList.length > 0) {
        const activeAccounts = accountsList.filter(
          (account) => account.member.status === "active"
        );
        const invitedAccounts = accountsList.filter(
          (account) => account.member.status === "invited"
        );
        dispatch(setAllAccountsV2Login(accountsList));
        dispatch(setActiveAccountsV2Login(activeAccounts));
        dispatch(setInvitedAccountV2Login(invitedAccounts));
        if (!selectedAccount) {
          dispatch(setSelectedAccountV2Login(activeAccounts[0]));
        } else {
          const updatedSelectedAcc = activeAccounts.find(
            (active) => active.id === selectedAccount.id
          );
          if (!isEqual(updatedSelectedAcc, selectedAccount))
            dispatch(setSelectedAccountV2Login(updatedSelectedAcc));
        }
        if (activeAccounts.length === 0 && invitedAccounts.length > 0) {
          if (location.pathname !== "/home/v2/notifications") {
            navigate("/home/v2/notifications", {
              replace: true,
              state: location,
            });
          }
        }
      } else {
        dispatch(setAllAccountsV2Login([]));
        dispatch(setActiveAccountsV2Login([]));
        dispatch(setInvitedAccountV2Login([]));
        dispatch(setSelectedAccountV2Login(null));
        if (location.pathname !== "/home/v2/agencyDetails") {
          navigate("/home/v2/agencyDetails", {
            replace: true,
            state: location,
          });
        }
      }
    }
  }, [
    selectedAccount,
    isInviteRoute,
    accessToken,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    accountsList,
    location.pathname,
    t,
    dispatch,
    navigate,
    location,
    setIsAccountsLoading,
    setToast,
  ]);
  return { isAccountsLoading };
}

export default useAccountsInfo;
