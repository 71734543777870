import { Box, CircularProgress } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { IconLogout, IconSettings, IconUser } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import PopupModal from "components/common/PopupModal/PopupModal";
import AnimatedButton from "components/custom/AnimatedButton";
import { useFlags } from "flagsmith/react";
import { fetchUserProfileDetails } from "modules/Profile/api";
import { IUserDetails } from "modules/Profile/types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  setAllAccountsV2Login,
  setSelectedAccountV2Login,
  signOut,
} from "store/features/authSlice";
import { authSelector, customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import { LSKeys, deleteFromLocalStorage } from "utils/ls.utils";

const UserPopover = () => {
  const { t } = useT();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customStyles = useSelector(customStylesSelector);
  const { portalAccount } = useSelector(authSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    v2_feature_set: { enabled: isV2FeatureEnabled },
  } = useFlags(["v2_feature_set"]);
  const selectedAccount = useSelector(
    (state: RootState) => state.auth.v2Login.selectedAccount
  );

  const {
    data: userDetails,
    isLoading: isUserDetailsLoading,
    isError: isUserDetailsError,
  } = useQuery<IUserDetails>({
    queryFn: fetchUserProfileDetails.queryFn,
    queryKey: fetchUserProfileDetails.queryKey,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    if (isV2FeatureEnabled) {
      deleteFromLocalStorage(LSKeys.ACCESS_TOKEN);
      dispatch(setSelectedAccountV2Login(null));
      dispatch(setAllAccountsV2Login([]));
      navigate("/v2/login");
    } else {
      dispatch(signOut());
      navigate("/login");
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-popover" : undefined;

  if (isUserDetailsLoading && isV2FeatureEnabled) return <CircularProgress />;
  if (isUserDetailsError && isV2FeatureEnabled) {
    return null;
  }
  return (
    <>
      <Box>
        <AnimatedButton
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          sx={{
            minWidth: "fit-content",
            padding: "8px",
            borderRadius: "50%",
          }}
        >
          <IconUser stroke={1.5} size="1.5rem" color="white" />
        </AnimatedButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{
            mt: "10px",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box
            sx={{
              p: "20px",
              minWidth: "200px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            {isV2FeatureEnabled ? (
              <Typography
                sx={{
                  color: customStyles.muiTheme.palette.text.primary,
                  fontSize: "18px",
                  fontWeight: 600,
                  ml: "8px",
                }}
              >
                {`${userDetails?.first_name || ""} ${
                  userDetails?.last_name || ""
                }`}
              </Typography>
            ) : (
              <Typography
                sx={{
                  color: customStyles.muiTheme.palette.text.primary,
                  fontSize: "18px",
                  fontWeight: 600,
                  ml: "8px",
                }}
              >
                {`${portalAccount?.user?.first_name} ${portalAccount?.user?.last_name}`}
              </Typography>
            )}
            {isV2FeatureEnabled && !selectedAccount ? null : (
              <AnimatedButton
                sx={{ textTransform: "none", justifyContent: "flex-start" }}
                onClick={() =>
                  navigate(
                    isV2FeatureEnabled ? "/home/v2/profile" : "/home/settings"
                  )
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <IconSettings stroke={1.5} size="1.5rem" color="grey" />
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {t("SETTINGS")}
                  </Typography>
                </Box>
              </AnimatedButton>
            )}
            <AnimatedButton
              sx={{ textTransform: "none", justifyContent: "flex-start" }}
              onClick={handleOpenModal}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <IconLogout stroke={1.5} size="1.5rem" color="grey" />
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {t("LOGOUT")}
                </Typography>
              </Box>
            </AnimatedButton>
          </Box>
        </Popover>
      </Box>
      <PopupModal
        open={isModalOpen}
        title={t("POP_UPS.LOGOUT_POPUP.title")}
        content={t("POP_UPS.LOGOUT_POPUP.content")}
        btnText={t("POP_UPS.LOGOUT_POPUP.btnText")}
        onClose={handleCloseModal}
        onSave={handleSave}
      />
    </>
  );
};

export default UserPopover;
