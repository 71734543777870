import { Box, Grid, Typography } from "@mui/material";
import NotificationCard from "components/v2/Notifications/NotificationCard";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useT } from "translation";

function NotificationsList() {
  const { t } = useT();
  const invitedAccounts = useSelector(
    (state: RootState) => state.auth.v2Login.invitedAccounts
  );
  if (invitedAccounts.length === 0) {
    return (
      <Box display="flex" justifyContent={"center"} alignItems={"center"}>
        <Typography variant="h5">
          {t("NO_NEW_NOTIFICATION_AVAILABLE")}
        </Typography>
      </Box>
    );
  }
  return (
    <Grid container spacing={2}>
      {invitedAccounts.map((acc) => {
        return (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <NotificationCard
              description={acc.name}
              timeStamp={new Date(acc.created_at)}
              memberId={acc.member.id}
              accountId={acc.id}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default NotificationsList;
