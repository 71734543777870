import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import OutletHeader from "components/Home/Common/OutletHeader";
import NotificationsList from "components/v2/Notifications/NotificationsList";
import { animateRouteProps } from "constants/common";
import { useEffect } from "react";
import { useT } from "translation";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";

const Notifications = () => {
  const { t } = useT();
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["accounts", getFromLocalStorage(LSKeys.ACCESS_TOKEN)],
    });
  }, [queryClient]);
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "12px",
      }}
      {...animateRouteProps}
    >
      <OutletHeader title={t("NOTIFICATION_PAGE.title")} />
      <NotificationsList />
    </Box>
  );
};

export default Notifications;
