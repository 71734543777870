import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import { deleteMemberApi } from "modules/Agents/api";
import { useContext } from "react";
import { useT } from "translation";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";

const useDeleteMember = () => {
  const queryClient = useQueryClient();
  const { t } = useT();
  const { setToast } = useContext(ToastCtx);

  const deleteMemberMutation = useMutation<
    unknown,
    unknown,
    { memberId: string; accountId: string }
  >({
    mutationFn: ({ memberId, accountId }) =>
      deleteMemberApi(memberId, accountId),
    onSuccess: () => {
      setToast({
        severity: "success",
        text: t("MEMBER_DELETED_SUCCESSFULLY"),
      });
      queryClient.invalidateQueries({
        queryKey: ["accounts", getFromLocalStorage(LSKeys.ACCESS_TOKEN)],
      });
    },
    onError: ({ error }) => {
      const errorCode = error?.code || "";
      if (errorCode === "invalid_auth_key") {
        setToast({
          severity: "error",
          text: t("INVALID_AUTH_KEY"),
        });
        return;
      }
      if (errorCode === "insufficient_permissions") {
        setToast({
          severity: "error",
          text: t("NO_SUFFICIENT_PERMISSION"),
        });
        return;
      }
      if (errorCode === "invalid_member_id") {
        setToast({
          severity: "error",
          text: t("INVALID_MEMBER_ID"),
        });
        return;
      }
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  const deleteMember = (memberId: string, accountId: string) => {
    if (memberId && accountId) {
      deleteMemberMutation.mutate({ memberId, accountId });
    }
  };

  return {
    deleteMember,
    isDeleting: deleteMemberMutation.isPending,
    isDeleteSuccess: deleteMemberMutation.isSuccess,
  };
};

export default useDeleteMember;
