import { ERole } from "modules/Accounts/types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const useIsAdmin = () => {
  const selectedAccount = useSelector(
    (state: RootState) => state.auth.v2Login.selectedAccount
  );
  return useMemo(() => {
    if (!selectedAccount) return false;
    const member = selectedAccount.member;
    if (member.roles.includes(ERole.ADMIN)) {
      return true;
    }
    return false;
  }, [selectedAccount]);
};

export default useIsAdmin;
