import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useState } from "react";

import AnimatedTabPanel from "components/custom/AnimatedTabPanel";
import { animateRouteProps } from "constants/common";
import UpdateAgencyDetails from "modules/Profile/UpdateAgencyDetails";
import UpdateUserPersonalDetails from "modules/Profile/UpdateUserPersonalDetails";
import { useT } from "translation";

const SettingsPanel = () => {
  const { t } = useT();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            sx={{
              textTransform: "none",
            }}
            label={t("SETTINGS_PAGE_TAB_AGENCY")}
          />
          <Tab
            sx={{
              textTransform: "none",
            }}
            label={t("SETTINGS_PAGE_TAB_PERSONAL")}
          />
        </Tabs>
      </Box>
      <AnimatedTabPanel {...animateRouteProps} value={value} index={0}>
        <UpdateAgencyDetails />
      </AnimatedTabPanel>
      <AnimatedTabPanel {...animateRouteProps} value={value} index={1}>
        <UpdateUserPersonalDetails />
      </AnimatedTabPanel>
    </Box>
  );
};

export default SettingsPanel;
